<template>
  <div>
    <div class="row form-rounded-inputs">
      <div class="col-12 col-sm-6 ">
        <div class="form-group">
          <label for="" v-text=" $t('general.form.initialDate') "></label>
          <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.initialDate') ">
            <v-date-picker output-format='x' :formatted="CONST.DATE_FORMATS.DD_MM_YYYY"
              @input="fnDateNotBeforeTo() ; fnStringDates()" right v-model="dates.initial" :min-date=" CONST.TODAY "
              no-header only-date no-button-now dark no-button auto-close>
              <input type="text" class="form-control" readonly v-model=" stringDates.start " :disabled="disabledDates">
            </v-date-picker>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </v-validation>
        </div>
      </div>
      <div class="col-12 col-sm-6 ">
        <div class="form-group">
          <label for="" v-text=" $t('general.form.finishDate') "></label>
          <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.finishDate')">
            <v-date-picker output-format='x' :formatted="CONST.DATE_FORMATS.DD_MM_YYYY" right @input=" fnStringDates() "
              v-model="dates.finish" :min-date="notBefore" no-header only-date no-button-now dark no-button auto-close>
              <input type="text" class="form-control" readonly v-model=" stringDates.end " :disabled="disabledDates">
            </v-date-picker>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </v-validation>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      disabledDates:{
        type: Boolean,
        default: false,
      },
      initialDate: {
        type: String,
      },
      finishDate: {
        type: String,
      }
    },

    data() {
      return {
        notBefore: null,
        dates: {
          initial: null,
          finish: null,
        },
        stringDates: {
          start: null,
          end: null,
        },
      }
    },
    methods: {
      fnDateNotBeforeTo() {
        this.notBefore = this.$moment(this.dates.initial, 'x').add(1, 'days').format();
        this.fnDateResetFinishDate();
        this.fnStringDates();
      },
      fnDateResetFinishDate() {
        this.dates.finish = this.$moment(this.dates.initial, 'x').add(30, 'days').format('x');
      },
      fnStringDates() {
        this.stringDates.start = this.$moment(this.dates.initial, 'x').format(this.CONST.DATE_FORMATS.DD_MM_YYYY);
        this.stringDates.end = this.$moment(this.dates.finish, 'x ').format(this.CONST.DATE_FORMATS.DD_MM_YYYY);
        this.$emit('set-dates', this.dates);
      },
      fnResetDates() {
        this.dates.initial = new Date().setHours(0, 0, 0, 0);
        this.dates.finish = this.$moment(this.dates.initial).add(30, 'days').format('x');
        this.dates.initial = this.$moment(this.dates.initial).format('x');
        this.fnDateNotBeforeTo();
      },
    },
    mounted() {

      if (this.initialDate) {
        if (this.$moment(this.initialDate).format('x') != 'Invalid date') {
          this.dates.initial = this.$moment(this.initialDate).format('x');
          this.dates.finish = this.$moment(this.finishDate).format('x');
          this.notBefore = this.$moment(this.dates.initial, 'x').add(1, 'days').format();
          this.fnStringDates()
        } else {
          this.dates.initial = this.$moment(this.initialDate, 'x').format('x');
          this.dates.finish = this.$moment(this.finishDate, 'x').format('x');
          this.notBefore = this.$moment(this.dates.initial, 'x').add(1, 'days').format();
          this.fnStringDates()
        }
      } else {
        this.fnResetDates();
      }
      
    }
  }
</script>