<template>
  <div>
    <b-modal v-model="modal.shipping" :title="$t('dashboard.discounts.types.shipmentsDiscount')" content-class="card "
      header-class="card-header" cancel-variant="none" size="xl" @hidden="resetDiscount(); fnResetCarriersList() "
      :hide-footer="(carriersList.length == 0) " :cancel-title="$t('general.button.close')" centered>

      <v-observer tag="form" class=" animated fadeIn" ref="formShipmentDiscount" @submit.prevent="fnValidateDiscount"
        v-show=" carriersList.length > 0 ">

        <div class="row">
          <div class="col-12 col-lg-6">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.store') ">
              <div class="form-group">
                <label>
                  <span v-text="$t('general.form.store')"></span><br>
                  <small v-text="$t('informational.form.shippingDiscount.store')"></small>
                </label>
                <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                  <select class="custom-select" :class=" { 'is-invalid ':errors.length > 0 } "
                    v-model="newDiscount.storeId">
                    <option :value="null" selected v-text=" $t('general.form.select') ">
                    </option>
                    <option :value="store.id" v-for=" (store,index) in storesList " :key="index"
                      v-text=" store.name ? store.name : fnStoreURL(store.url) ">
                    </option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text"> <i class="fa "
                        :class=" loading.stores ? 'fa-spinner fa-spin':'fa-store' "></i> </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>

          <div class="col-12 col-lg-6 ">
            <v-validation rules="required|min:3|max:50" v-slot="{ errors }" :name=" $t('general.form.name') ">
              <div class="form-group">
                <label>
                  <span v-text="$t('general.form.name')"></span><br>
                  <small v-text="$t('informational.form.coupon.name')"></small>
                </label>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="newDiscount.name"
                    :class=" { 'is-invalid':(errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa fa-keyboard "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </v-validation>
          </div>

        </div>
        <hr class="mt-1">
        <div class="row">
          <div class="col-12 col-lg-6 ">
            <div class="form-group ">
              <label class="w-100">
                <div class="row">
                  <div class="col">
                    <span v-text=" $t('general.form.discount') "> </span>
                  </div>
                  <div class="col-auto ">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isPrecentage" v-model="newDiscount.isPercent">
                      <label class="form-check-label" for="isPrecentage" v-text="$t('general.form.inPercentage')">
                      </label>
                    </div>
                  </div>
                </div>
                <small v-text="$t('informational.form.shippingDiscount.discount')"></small>
              </label>
              <v-validation rules="required|min_value:5" v-slot="{ errors }" :name=" $t('general.form.discount') ">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.amount"
                    :placeholder="newDiscount.isPercent ? '100%':'10$' " :max="newDiscount.isPercent ? 100 : '' "
                    :class=" { 'is-invalid': (errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa" :class=" newDiscount.isPercent ? 'fa-percentage':'fa-dollar-sign' "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>
          </div>
          <div class="col-12 col-lg-6 ">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.currency') ">
              <div class="form-group">
                <label>
                  <span v-text="$t('general.form.currency')"></span><br>
                  <small v-text="$t('informational.form.shippingDiscount.currency')"></small>
                </label>
                <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                  <select class="custom-select " :class=" { 'is-invalid ':errors.length > 0 } "
                    v-model="newDiscount.currency">
                    <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                    <option :value="currency" v-for=" (currency,index) in CONST.CURRENCIES " :key="index"
                      v-text=" currency "> </option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text">
                      <i class="fa fa-money-bill"></i>
                    </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>
        </div>

        <hr class="mt-1">

        <div class="row justify-content-end">
          <div class="col-12 col-lg-6">
            <v-validation
              :rules=" !newDiscount.isPercent ? ('required|min_value:' + (Number(newDiscount.amount) )): 'required|min_value:5' "
              v-slot="{ errors }" :name=" $t('general.form.minPurchase') ">
              <div class="form-group">
                <label>
                  <span v-text="$t('general.form.minPurchase')"></span><br>
                  <small v-text="$t('informational.form.minPurchase')"></small>
                </label>
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.minPurchase" placeholder="50"
                    :class=" { 'is-invalid':(errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa fa-dollar-sign "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </v-validation>
          </div>


          <div class="col-12 col-lg-6 animated fadeIn" v-show="newDiscount.isPercent">
            <div class="form-group ">
              <label>
                <span v-text="$t('general.form.maxAmount')"></span><br>
                <small v-text="$t('informational.form.shippingDiscount.maxShipment')"></small>
              </label>
              <v-validation :rules=" newDiscount.isPercent ? 'required|min_value:1': '' " v-slot="{ errors }"
                :name=" $t('general.form.maxAmount') ">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.maxPurchase"
                    :class=" { 'is-invalid': (errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa fa-shopping-bag "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="w-100">
                <div class="row">
                  <div class="col">
                    <span v-text=" $t('general.form.limitOfUse') "> </span>
                  </div>
                  <div class="col-auto ">
                    <div class="form-check ">
                      <input type="checkbox" class="form-check-input" id="isUnlimited"
                        v-model="newDiscount.isUnlimited">
                      <label class="form-check-label" for="isUnlimited" v-text="$t('general.form.unlimited')"> </label>
                    </div>
                  </div>
                </div>
                <small v-text="$t('informational.form.shippingDiscount.limit')"></small>
              </label>
              <v-validation rules="required|min_value:1|max_value:500" v-slot="{ errors }"
                :name=" $t('general.form.limitOfUse') ">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.available" placeholder="100"
                    :class=" { 'is-invalid':(errors.length > 0 ) } " :disabled="newDiscount.isUnlimited">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa " :class=" newDiscount.isUnlimited ? 'fa-infinity':'fa-hashtag' "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>
          </div>
        </div>

        <hr class="mt-1">

        <div class="row">
          <div class="col-12">
            <div class="mb-2">
              <p class="mb-2">
                <strong class="f-w-500" v-text="$t('general.form.carriers')"> </strong> <br>
                <small v-text="$t('informational.form.shippingDiscount.carriers')"> </small>
              </p>

              <table class="table table-bordered table-light table-hover">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" id="selectAllCarriers" :checked="selectAllCarriers"
                        @change="fnSelectAllCarriers">
                    </th>
                    <th class="col f-w-500 ">
                      <label for="selectAllCarriers" class="m-0 cursor-pointer"
                        v-text="$t('general.form.selectAll')"></label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for=" (carrier,indexCarrier) in carriersList">
                    <tr :key="indexCarrier">
                      <td>
                        <input type="checkbox" v-model="carriersSelect" :id="indexCarrier + 'carrier' "
                          :value="carrier.id" @change="fnSelectAllServices(carrier.id)">
                      </td>
                      <td>
                        <div class="row">
                          <div class="col-12">
                            <label :for="indexCarrier + 'carrier' " class="text-upercase mb-0 cursor-pointer w-100">
                              <div class="d-flex align-items-center ">
                                <img v-image="carrier.image" alt="" class="img-contain-40 px-1 mr-2">
                                <div>
                                  <p class="m-0 text-capitalize">
                                    <strong v-text="carrier.name"
                                      :class=" carriersSelect.includes(carrier.id) ? '' : 'f-w-500' "></strong>
                                  </p>
                                  <p class="m-0 animated fadeIn" v-show="carriersSelect.includes(carrier.id)"
                                    v-text="$t('informational.form.shippingDiscount.carriersServices')"> </p>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <div class="table-tr animated fadeIn bg-white "
                      :class=" { 'border-bottom' : (indexService == (carrier.services.length -1) )  } "
                      v-for=" (service,indexService) in carrier.services" :key="carrier.id + indexService + 's'"
                      v-show="carriersSelect.includes(carrier.id)">
                      <td>
                      </td>
                      <td>
                        <div class="row">
                          <div class="col-12 ">
                            <div class="row">
                              <div class="col-auto">
                                <input type="checkbox" :id="carrier.id + indexService + 'service'" :value="service.id"
                                  v-model="servicesSelect" @change="fnUnselectCarrier(carrier.id)">
                              </div>
                              <div class="col">
                                <label class="text-capitalize mb-0 cursor-pointer w-100"
                                  :for="carrier.id + indexService + 'service'">
                                  <span
                                    v-text=" $te('general.carriers.services.' + service.name) ? $t('general.carriers.services.' + service.name) : service.name  "></span>
                                  <span class="text-muted m-0" v-if="$te('general.carriers.services.' + service.name)">
                                    <small> -
                                      <span
                                        v-text=" $t('general.carriers.services.' + service.name + '_description') "></span>
                                    </small>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </div>
                  </template>
                </tbody>
              </table>

              <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.carriers') ">
                <div class="form-group m-0">
                  <input type="checkbox" class="input-group is-invalid " hidden v-model="carriersSelect">
                  <div class=" bg-danger p-1 invalid-feedback mt-0" v-if="errors.length > 0">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>

            </div>

            <div>
              <p class="m-0">
                <strong class="f-w-500" v-text="$t('general.form.availabilityDate')"> </strong>
              </p>
              <div v-if="newDiscount.applyDates">
                <p>
                  <small>
                    <span v-text="$t('informational.form.coupon.availabilityDate') + ' '"></span>
                    <a class="text-info cursor-pointer" @click="newDiscount.applyDates = !newDiscount.applyDates"
                      v-text=" $t('general.button.hideForm') "></a>.
                  </small>
                </p>
                <FormaDates @set-dates=" (dates)=>{ newDiscount.dates = dates } "
                  :initialDate="newDiscount.dates.initial" :finishDate="newDiscount.dates.finish" />
              </div>
              <p v-else>
                <small>
                  <span v-html="$t('informational.form.coupon.availabilityDateMessage') + ' '"></span>
                  <a @click="newDiscount.applyDates = !newDiscount.applyDates"
                    class="text-lowercase text-info cursor-pointer" v-text=" $t('general.button.here') "></a>.
                </small>
              </p>
            </div>
          </div>
        </div>
        <input type="submit" hidden>

      </v-observer>
      <div v-show="carriersList.length == 0">
        <h6 class="my-3">
          <span class="bg-warning p-1" v-html="$t('messages.discounts.shipping.carrier.title')"> </span>
        </h6>
        <p v-html="$t('messages.discounts.shipping.carrier.message')"></p>
      </div>

      <template v-slot:modal-footer>
        <p class="text-right">
          <button class="btn" v-text="$t('general.button.close')" @click="changeModal({ shipping: false })"></button>
          <button class="btn btn-success" :disabled="loading.newDiscount" @click="fnValidateDiscount()">
            <span v-text=" newDiscount.id ? $t('general.button.update') : $t('general.button.create') "
              v-if=" !loading.newDiscount "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </button>
        </p>
      </template>

    </b-modal>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  import FormaDates from "@/components/FormDates.vue";
  export default {
    data() {
      return {
        carriersSelect: [],
        servicesSelect: [],
      }
    },
    components: {
      FormaDates
    },
    computed: {
      ...mapState('discounts', ['modal', 'loading', 'newDiscount', 'storesList', 'carriersList',
        'discountsCarriersList'
      ]),
      // ...mapGetters('discounts', ['']),
      selectAllCarriers() {
        return (this.carriersSelect.length == this.carriersList.length);
      },
    },
    watch: {
      discountsCarriersList() {
        if (this.discountsCarriersList && (this.discountsCarriersList.length > 0)) {
          this.fnSetCarriersList(this.discountsCarriersList);
        }
      },
      carriersSelect() {
        this.fnCreateCarriersList();
      },
      servicesSelect() {
        this.fnCreateCarriersList();
      }
    },
    methods: {
      ...mapMutations('discounts', ['resetDiscount', 'changeModal']),
      ...mapActions('discounts', ['fnApiCreateShipmentDiscount']),
      fnResetCarriersList() {
        this.carriersSelect = [];
        this.servicesSelect = [];
      },
      async fnValidateDiscount() {
        if (await this.$refs['formShipmentDiscount'].validate()) {
          this.fnApiCreateShipmentDiscount(this.newDiscount);
        } else {}
        return false
      },

      fnSelectAllCarriers() {
        if (this.carriersSelect.length < this.carriersList.length) {
          this.carriersSelect = [];
          this.servicesSelect = [];
          this.carriersList.map(carrier => {
            this.carriersSelect.push(carrier.id);
            carrier.services.map(service => {
              this.servicesSelect.push(service.id)
            })
          })
        } else {
          this.carriersSelect = [];
          this.servicesSelect = [];
        }

      },
      fnUnselectCarrier(carrierId) {
        let tempCarrier = this.carriersList.find(carrier => carrier.id == carrierId);
        let tempCondition = true;
        for (let service of tempCarrier.services) {
          let tempIndex = this.servicesSelect.indexOf(service.id)
          if (tempIndex >= 0) {
            tempCondition = false;
            break;
          }
        }

        if (tempCondition) {
          this.carriersSelect.splice(this.carriersSelect.indexOf(carrierId), 1);
        }
      },
      fnSelectAllServices(carrierId) {
        let tempCarrier = this.carriersList.find(carrier => carrier.id == carrierId);
        if (this.carriersSelect.includes(carrierId)) {
          for (let service of tempCarrier.services) {
            this.servicesSelect.indexOf(service.id) === -1 ? this.servicesSelect.push(service.id) : false;
          }
        } else {
          for (let service of tempCarrier.services) {
            let tempIndex = this.servicesSelect.indexOf(service.id);
            if (tempIndex >= 0) {
              this.servicesSelect.splice(tempIndex, 1)
            }
          }
        }
      },
      fnCreateCarriersList() {
        this.newDiscount.carriers = [];
        this.carriersSelect.map(carrierId => {
          let carrier = this.carriersList.find(carrier => carrier.id == carrierId);
          carrier.tempServices = [];
          carrier.services.map(service => {
            if (this.servicesSelect.findIndex(serviceId => serviceId == service.id) !== -1) {
              carrier.tempServices.push(service.name);
            }
          })
          this.newDiscount.carriers.push({
            name: carrier.name,
            services: carrier.tempServices,
          });
        });
      },
      fnSetCarriersList(carriersList) {
        carriersList.map(tempCarrier => {
          let carrierData = this.carriersList.find(carrier => carrier.name == tempCarrier.name);
          this.carriersSelect.push(carrierData.id);
          tempCarrier.services.map(tempService => {
            let serviceData = carrierData.services.find(service => service.name == tempService);
            this.servicesSelect.push(serviceData.id);
          })
        });
      },
    },
    mounted() {

    },
    updated() {

    },
  }
</script>

<style>
</style>